body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn {
  width: 100%;
  text-align: center;
}

.giftCardCodeSearchInput {
  letter-spacing: 3px;
}

.formularWrapper {
  max-width: 600px !important;
  margin: 0px auto !important;
}

.logoHeader {
  width: 50px;
}

@media screen and (min-width: 992px) {
  #responsive-navbar-nav {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  #responsive-navbar-nav button {
    margin-top: 0px !important;
  }
}

.customer-card p {
  margin-bottom: 0px;
}